
function fixedMainNav() {
    if (window.pixelLabs.scrollPosition > (window.pixelLabs.navHeightMax*2)) {
        $('#top').addClass('js-Follow-Me-Around');
        $("#nav-open-btn").click(function (){
            $('html, body').animate({
                scrollTop: $("#main").offset().top
            }, 0);
        });
    }
    else {
        $('#top').removeClass('js-Follow-Me-Around');
    }
}

function navLoad(){
    if ($("#top").hasClass('wink-available')) {
        if (window.pixelLabs.windowWidth > window.pixelLabs.winkWidthbegin && window.pixelLabs.windowHeight > window.pixelLabs.winkHeightbegin) {
            $('body').addClass('wink-ready');
            $(window).scroll(function () {
                $(fixedMainNav);
            });
        } else {
            $('body').removeClass('wink-ready');
            $('#top').removeClass('js-Follow-Me-Around');
        }
    }
}


$(fixedMainNav);
$(navLoad);

$(window).resize(function(){
    $(navLoad);
});



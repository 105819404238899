require('./foundation.js')
require('./imagesloaded.js')
require('./maxPane.js')
require('./twoFro.js')
require('./dropMenu.js')
require('./navTrackers.js')
require('./offcanvas.js')
require('./jquery.vide.js')
require('./winkNav.js')
require('./header-contact.js')
require('./jquery.beefup.min.js')
require('./footer.js')
// require('./paralax.js')
// require('./preload.js')
$( document ).on( 'click', '.chat',function() {
    var thisHeight = $("#header-contact").outerHeight();
    var contactHeight = $("#header-contact").children("#Form_HeaderForm").outerHeight();


    $("#header-contact").removeClass('success');
    if (thisHeight <= 5){
        $("#header-contact").css('height',contactHeight);
        if(window.pixelLabs.windowWidth <= 768 || window.pixelLabs.windowHeight <= window.pixelLabs.winkHeightbegin){
            $('html, body').animate({
                scrollTop: $("#top").offset().top
            }, 500);
        }
    } else {
        $("#header-contact").css('height',"0px");

    }
});
$(document).ready(function() {
    if ($("#header-contact").hasClass('success')) {
        var contactHeight = $("#header-contact").children("#Form_HeaderForm").outerHeight();
        $("#header-contact").css('height', contactHeight);
    }
});
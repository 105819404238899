function containerHeight($) {
    $(".js-twoFro").each(function(){
        if (windowWidth > 787) {
            var imageHeight = $(this).children('.js-first').outerHeight();
            var detiailsHeight = $(this).children('.js-second').outerHeight();
            if ( (detiailsHeight ) > imageHeight) {
                $(this).children('.js-second').css('margin-top', 0);
                marginAdjust = (detiailsHeight - imageHeight ) / 2;
                $(this).children('.js-first').css('margin-top', marginAdjust);
            }
            else {

                marginAdjust = (imageHeight - detiailsHeight) / 2;
                $(this).children('.js-second').css('margin-top', marginAdjust);
                $(this).children('.js-first').css('margin-top', 0);
            }
        }
    });
}

function halfSquare($) {
    $(".js-halfsquare").each(function(){
        if (windowWidth > 787) {
            var blockHeight = ($(this).outerWidth()) *0.5;
            $(this).css('height', blockHeight);
        }
    });
}

function perfectSquare($) {
    $(".js-perfectsquare").each(function(){
        if ($(this).hasClass('smaller') && (windowWidth > 300)){
            var blockHeight = $(this).outerWidth();
            $(this).css('height', blockHeight);
        }
        else if (windowWidth > 787) {
            var blockHeight = $(this).outerWidth();
            $(this).css('height', blockHeight);
        }
    });
}

function blockHeight($) {
    if (windowWidth > 787) {
        $(".js-height-block").each(function () {
            $(this).css('height', 'auto');
            var blockHeight = $(this).outerHeight();
            $(this).css('height', blockHeight);
        });
    }else{
        $(this).css('height', 'auto');
    }
}

function blockHeightShift($) {
    if (windowWidth > 787) {
        $(".js-shift").each(function(){
            $(this).css('height', 'auto');
            var blockHeight = $(this).outerHeight();
            var shiftHeight = $(this).children('.js-adjust').outerHeight();
            var shiftMargin = (blockHeight - shiftHeight) / 2;
            $(this).css('height', blockHeight);
            if (blockHeight > shiftHeight){
                $(this).children('.js-adjust').css('margin-top', shiftMargin);
            }
        });
    }
    else{
        $(".js-shift").each(function(){
            $(this).children('.js-adjust').css('margin-top', 'auto');
            $(this).css('height', 'auto');
        });
    }
}

function containerHeightFull($) {
    $(".js-twoFro-full").each(function(){
        $(this).children('.js-first').one('load',function(){
            var imageHeight = $(this).outerHeight();
            var detiailsHeight = $(this).siblings('.js-second').outerHeight();
            if ( (detiailsHeight ) > imageHeight) {
                $(this).parent().css('height', detiailsHeight);
                $(this).siblings('.js-second').css('margin-top', 0);
                $(this).parent().css('height', detiailsHeight);
                marginAdjust = (detiailsHeight - imageHeight ) / 2;
                $(this).css('margin-top', marginAdjust);
            }
            else {
                $(this).parent().css('height', imageHeight);
                marginAdjust = (imageHeight - detiailsHeight) / 2;
                $(this).siblings('.js-second').css('margin-top', marginAdjust);
                $(this).css('margin-top', 0);

            }
        }).each(function(){
            if(this.complete) $(this).load();
        });
    });
}


function bottomAllign($) {
    $(".js-bottomAllign").each(function(){
        if (windowWidth > 787) {
            $(this).children('.js-bottom').one('load',function(){
                var containerHeight = $(this).outerHeight();
                var imageHeight = $(this).outerHeight();
                var detiailsHeight = $(this).siblings('.js-content').outerHeight();
                var shiftMargin = (containerHeight - detiailsHeight) / 2;
                if ( (detiailsHeight ) > imageHeight) {
                    $(this).css('position', 'absolute');
                    $(this).css('max-height', '2000');
                    $(this).siblings('.js-content').css('margin-top', 0);
                }
                else {
                    $(this).css('position', 'relative');
                    $(this).css('max-height', '2000');
                    $(this).siblings('.js-content').css('margin-top', shiftMargin);
                }
            }).each(function(){
                if(this.complete) $(this).load();
            });
        }
        else {
            $(this).children('.js-bottom').css('position', 'relative');
            $(this).children('.js-bottom').css('max-height', '2000');
            $(this).children('.js-content').siblings('.js-content').css('margin-top', 0);
        }
    });
}

setTimeout(function(){
    containerHeight(jQuery);
}, 3000);

$(document).ready(function(){
    windowWidth = $( document ).width();
    containerHeight(jQuery);
    blockHeight(jQuery);
    containerHeightFull(jQuery);
    blockHeightShift(jQuery);
    bottomAllign(jQuery);
    perfectSquare(jQuery);
    halfSquare(jQuery);
});



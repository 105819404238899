window.mP = {};

/*----------------- Get the inner height of an element with its Padding and margin -------------------*/

function getElemHeight(e){
    mP.elemHeight = e.height();
    mP.elemMarginVertical = parseInt(e.css("margin-top")) + parseInt(e.css('margin-bottom'));
    mP.elemPaddingVertical = parseInt(e.css('padding-top')) + parseInt(e.css('padding-bottom'));
    mP.elemTotalHeight = mP.elemHeight + mP.elemMarginVertical + mP.elemPaddingVertical;
}
/*----------------- Define the content height  -------------------*/

function getContent(e){
    parCount = e.find('.js-mPcontent').length;
    if(parCount == 0){
        mP.contentAvaliable = false;
    } else {
        mP.contentAvaliable = true;
    }
}

function getContentHeight(e) {
    mP.positionData = true;
    mP.positionData = e.data('position');
    if (mP.contentAvaliable == true){
        mP.cc = e.find('.js-mPcontent');
        var childCount = mP.cc.find('.js-vert').length;
        thisElemMarginVertical = parseInt(mP.cc.css("margin-top")) + parseInt(mP.cc.css('margin-bottom'));
        mP.contentHeight = 0;
        if (childCount == 0) {
            getElemHeight(mP.cc);
            mP.contentHeight = mP.elemTotalHeight - thisElemMarginVertical;
        } else {
            mP.contentHeight = thisElemMarginVertical + thisElemPaddingVertical;
            mP.cc.find('.js-vertical').each(function () {
                mP.contentHeight = mP.contentHeight + getElemHeight($(this));

            });
        }
    } else {
        mP.contentHeight = 0;
    }
}


function getButton(e){
    mP.jb = e.find('.jump-button-container');
    mP.jumpHeight = 0;
    if (mP.jb.length > 0){
        getElemHeight(mP.jb);
        mP.jumpHeight = mP.elemTotalHeight;
    }
}

function getActiveWidth(e){
    widthData = e.data('width');
    if(widthData != undefined){
        mP.startWidth = widthData
    }
}

function getBuffer(e){
    widthBufffer = e.data('buffer');
    if(widthBufffer != undefined){
        mP.bufferHeight = widthBufffer
    }
}

function getNavStats(e){
    mP.NavActive = false;
    if (window.pixelLabs.windowWidth > window.pixelLabs.winkWidthbegin && $('#top').hasClass('wink-available')){
        mP.NavActive = true;
    }
    if(mP.NavActive == true || mP.includeNav == true){
        if(e.hasClass('first')){
            mP.navHeight = window.pixelLabs.navHeightMax;
        } else {
            mP.navHeight = window.pixelLabs.navHeightMin;
        }
    }   else {
        mP.navHeight = 0;
    }
}



function setCoefHeight(e){
    var coeficientData = e.data('coef');
    if (coeficientData != undefined ){
        mP.coeficient = coeficientData;
    }
    mP.coefHeight = window.pixelLabs.windowHeight * mP.coeficient;
}

function setBoundryHeight(){
    mP.targetHeight = mP.contentHeight + mP.jumpHeight + mP.navHeight + mP.bufferHeight;
}

function checkActive(){
    mP.scaleActive = false;
    if(window.pixelLabs.windowWidth >= mP.startWidth){
        mP.scaleActive = true;
    }
}


function checkOverflow(){
    mP.withinBoundries = false;
    if(mP.coefHeight > mP.targetHeight){
        mP.withinBoundries = true;
    }
}


function setContainerHeight(e){
    mP.ActivecontainerHeight;
    if (mP.withinBoundries == true){
        mP.ActivecontainerHeight = mP.coefHeight;
    } else {
        mP.ActivecontainerHeight = mP.targetHeight;
    }
    e.css('height', mP.ActivecontainerHeight);
}

function setContentsPosition(){
    if (mP.withinBoundries == true){
        mP.equalMargin = (mP.ActivecontainerHeight -  mP.contentHeight - mP.jumpHeight - mP.navHeight) * 1.0;
        mP.marginTop = mP.navHeight +  (mP.equalMargin * 0.65);
        mP.marginBottom = mP.equalMargin * 0.35;
    } else {
        mP.equalMargin = mP.bufferHeight * 0.5;
        mP.marginTop = mP.navHeight +  mP.equalMargin;
        mP.marginBottom = mP.equalMargin;
    }
    mP.cc.css('margin-top',mP.marginTop);
    mP.cc.css('margin-bottom',mP.marginBottom);


}


function mPDebugReset(){
    $('#mpErrorBlock').remove();
    $('.mp-debug-track').remove();
}


function mPDebug(e){
    if(e.hasClass('mpDebug')){
        var errorCheckHolder = '<div id="mpErrorBlock"></div>'
        var marginTop = '<div style="height:'+mP.marginTop+'px" class="mp-debug-track MarginTop"><span>Margin Top '+mP.marginTop+'px</span></div>';
        var contentsHeight = '<div style="margin-top:'+mP.marginTop+'px; height:'+mP.contentHeight+'px " class="mp-debug-track ContainerHeight"><span>Content height Top '+mP.contentHeight+'px</span></div>';
        var marginBottom = '<div style="margin-top:'+(mP.marginTop + mP.contentHeight)+'px; height:'+mP.marginBottom+'px " class="mp-debug-track MarginBottom"><span>Margin Bottom '+mP.marginBottom+'px </span></div>';
        var buttonHeight = '<div style="margin-top:'+(mP.marginTop + mP.contentHeight + mP.marginBottom)+'px; height:'+(mP.jumpHeight)+'px " class="mp-debug-track ButtonHeight"><span>Button Height '+mP.jumpHeight+'px </span></div>';
        e.append(errorCheckHolder);
        e.prepend(marginTop);
        e.prepend(contentsHeight);
        e.prepend(marginBottom);
        e.prepend(buttonHeight);
        widthNaNCheck = mP.startWidth * mP.startWidth;
        if (mP.startWidth == null || mP.startWidth == undefined || mP.startWidth == NaN){
            var noWidthError ='<span class="error">Starting width is undefined or invalid</span>';
            $('#mpErrorBlock').append(noWidthError);
        } else {
            var startWithMessage ='<span class="message">Starting width is '+mP.startWidth+'px</span>';
            $('#mpErrorBlock').append(startWithMessage);
        }
        if (mP.withinBoundries == true){
            var boundrySettings ='<span class="message">Contents is within boundry limits</span>';
            $('#mpErrorBlock').append(boundrySettings);
        } else {
            var boundrySettings ='<span class="message">Contents greater than window height</span>';
            $('#mpErrorBlock').append(boundrySettings);
        }
        if (mP.contentAvaliable == true){
            var jumpButtonCheck = mP.cc.find('.jump-button-container');
            if (jumpButtonCheck.length > 0){
                var boundrySettings ='<span class="error">Your Jump Button is a child of the contents block, please make it a sibling</span>';
                $('#mpErrorBlock').append(boundrySettings);
            }
        }
        //console.log('Boundry height ' +  mP.targetHeight);
        //console.log('sizing active');
        //console.log('Set height ' + mP.ActivecontainerHeight);
        //console.log('Set margin in ' + mP.marginTop);
        //console.log('Set margin out ' + mP.marginTop);
        //console.log('No children');
        //console.log('Content Height '+ mP.contentHeight);
        //console.log('Jump Button Height '+mP.jumpHeight);
        //console.log('Buffer Height '+mP.bufferHeight);
        console.log('Nav height '+mP.navHeight);
        //console.log('Total content Height '+mP.contentHeight);
        //console.log('Window Height '+window.pixelLabs.windowHeight);
        //console.log('Coef Height '+ mP.coefHeight);
        //console.log('Start width '+mP.startWidth);
    }
}

function runMaxPane(MP){
    getContent(MP)
    /* Get content height */
    getContentHeight(MP);
    /* Get Button height */
    getButton(MP);
    /* Get Buffer height */
    getBuffer(MP);
    getNavStats(MP);

    /* Set boundry height */
    setCoefHeight(MP);
    setBoundryHeight();
    checkOverflow();
    setContainerHeight(MP);
    if (mP.contentAvaliable == true && mP.positionData !== false) {
        setContentsPosition();
    }
    mPDebug(MP);
}


function maxPane(){
    $(".js-mP").each(function(){
        /* -- Set global defaults -- */
        mP.bufferHeight = 0;
        mP.startWidth = 769;
        mP.coeficient = 1.0;
        mP.includeNav = true;
        mP.debug = false;

        getActiveWidth($(this));
        checkActive();
        if(mP.scaleActive == true){
            runMaxPane($(this));
        }
    });
}

$('body').imagesLoaded( function() {
    maxPane();
});

$(window).resize(function(){
    mPDebugReset();
    maxPane();
});


function dropMenu($) {
    windowWidth = window.pixelLabs.windowWidth;
    if (windowWidth > 1025) {
        $(".js-drop-pos").each(function(){
            var dropMenu = $(this).children().children('.dropMenu');
            var dropHeight = $(dropMenu).children().children('.menu-holder').outerHeight();
            $(this).children().children('.dropMenu').css("height", '0');
            $(this).mouseenter(function() {
                var headerHeight = $('#top').outerHeight();
                $(this).children().children('.dropMenu').addClass('open');
                $(this).children().children('.dropMenu').css("margin-top", headerHeight);
                $(this).children().children('.dropMenu').css("height", dropHeight);
            });
            $(this).mouseleave(function() {
                $(this).children().children('.dropMenu').removeClass('open');
                $(this).children().children('.dropMenu').css("height", '0');
            });
            $(this).find(".js-matchHeight-menu").each(function(){
                var heightMatch = 0;
                $(this).children('.columns').each(function(){
                    var elementHeight = $(this).outerHeight();
                    if (elementHeight > heightMatch){
                        heightMatch = elementHeight;
                    }
                });
                $(this).children('div').css('height', heightMatch);
            });
        });
    }
    else{
        $(".js-drop-pos").each(function(){
            $(this).children().children('.dropMenu').css("display","block");
        });
    };
}


(function($,sr){
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    var debounce = function (func, threshold, execAsap) {
        var timeout;
        return function debounced () {
            var obj = this, args = arguments;
            function delayed () {
                if (!execAsap)
                    func.apply(obj, args);
                timeout = null;
            };
            if (timeout)
                clearTimeout(timeout);
            else if (execAsap)
                func.apply(obj, args);
            timeout = setTimeout(delayed, threshold || 100);
        };
    }
    // smartresizemenu
    jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
})(jQuery,'smartresizemenu');
// usage:

$(window).on('load', function() {
    dropMenu(jQuery);
});

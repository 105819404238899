$(document).ready(function(){
    var navPixMin = $("#js-min-nav-height").outerHeight();
    window.pixelLabs.navHeightMin = navPixMin;
    var navPixMax = $("#js-max-nav-height").outerHeight();
    window.pixelLabs.navHeightMax = navPixMax;
    var navWinkMinHeight = $("#js-min-height-wink").outerHeight();
    window.pixelLabs.winkHeightbegin = navWinkMinHeight;
    var navWinkMinWidth = $("#js-min-width-wink").outerHeight();
    window.pixelLabs.winkWidthbegin = navWinkMinWidth;
    var navWinkMin = $("#js-offCanvas-end").outerWidth();
    window.pixelLabs.offCanvasEnd = navWinkMin;
});